.ingredient-template-container {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}

.ingredient-template-color {
    opacity: 0.2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ingredient-template-icon {
    width: 80%;
    height: 80%;
    object-fit: cover;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ingredient-allowed-in-template-tag {
    font-size: 9px;
	font-weight: 400;
	padding: 1px 4px;
    border-radius: 4px;
    text-transform: capitalize;
    margin-right: 5px;
}

.ingredient-template-name {
    font-size: 14px;
    font-weight: 500;
}

.ingredient-template-percentage {
	font-size: 12px;
	font-weight: 500;
}

.highlighted-row {
    background-color: #c7d2fe8c !important;
}