.header-menu {
    
    margin-bottom: 3rem;   
}

@media (min-width: 1200px) {
    .header-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (min-width: 1400px) {
    .header-menu {
        max-width: calc(50% + 214.1875px);
    }
}

.header-menu h3 {
    margin: 0;
    background-color: var(--color-dark-green);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

.menu {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

@media (min-width: 1200px) {
    .menu {
        margin-bottom: 0;
    }
}

.menu-button {
    margin-right: 1rem;
}

div.p-tabview-nav {
    margin-left: 4rem !important;
}