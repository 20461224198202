.container-screenshot {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	height: 100%;
}

.screenshot {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

