.movies-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.movies-list-item {
    margin-bottom: 1rem;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
    overflow: hidden;
}

.movies-list-item span {
    width: 180px;
    padding: .25rem 0.5rem 0;
}