.machine-stats {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 20px;
}

@media (min-width: 375px) {
	.machine-stats {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 768px) {
	.machine-stats {
		grid-template-columns: repeat(3, 1fr);
	}
}

.machine-stats-card {
	padding: 16px;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(61, 61, 61, 0.089);
	border: 1px solid var(--color-light-grey);
	height: 178.175px;
}

.filter-active {
	border: 1px solid var(--color-dark-green);
}

.machine-stats-card-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.machine-stats-card-header h3 {
	margin: 0;
}

.machine-stats-card-status {
	margin-bottom: 4px;
	cursor: pointer;
	opacity: 0.9;
	transition: all 0.1s ease-in;
}

.machine-stats-card-status:hover {
	opacity: 1;
}

.machine-stats-card-status:hover .machine-stats-card-status-flex p {
	color: var(--color-black);
}

.machine-stats-card-status:hover .machine-stats-card-status-flex p:first-child {
	transform: translateX(3px);
}

.machine-stats-card-status:hover progress {
	scale: 1.02;
}

.machine-stats-card-status-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.machine-stats-card-status-flex p {
	margin: 0;
	color: var(--color-dark-grey);
	font-weight: 500;
	font-size: 12px;
	margin-bottom: -4px;
	transition: all 0.1s ease-in;
}

progress {
	border: 0;
	height: 6px;
	width: 100%;
	border-radius: 200px;
	background-color: #fefefe;
	transition: all 0s ease-in;
}
progress::-webkit-progress-bar {
	border: 0;
	height: 6px;
	width: 100%;
	border-radius: 200px;
	background-color: #e6e6e6;
}
progress::-webkit-progress-value {
	border: 0;
	height: 6px;
	width: 100%;
	border-radius: 200px;
	background-color: #e6e6e6;
}
progress::-moz-progress-bar {
	border: 0;
	height: 6px;
	width: 100%;
	border-radius: 200px;
	background-color: #e6e6e6;
}

.progress::-webkit-progress-value {
	background-color: var(--color-dark-green);
}

.progress {
	background-color: #2196f3;
}

.progress-offline::-webkit-progress-value {
	background-color: var(--color-light-red);
}
.progress-offline {
	background-color: var(--color-light-red);
}

.progress-online::-webkit-progress-value {
	background-color: var(--color-light-green);
}
.progress-online {
	background-color: var(--color-light-green);
}

.clear-filter-button {
	cursor: pointer;
}

.clear-filter-button i {
	color: var(--color-dark-grey);
	transition: all 0.15s ease-in;
}

.clear-filter-button:hover i {
	transform: rotate(180deg);
	transform-origin: center;
	color: var(--color-light-red);
}
