.App {
	text-align: center;
	height: 100%;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #005c64;
}

.Main {
	min-height: 100vh;
	display: container;
	flex-flow: column nowrap;
}

.login-column {
	background-color: #005c64 !important;
}
.container_table {
	height: 290px;
	overflow-y: scroll; /* or auto */
}

.flex-container {
	display: flex;
	flex-direction: row;
	background-image: url('./assets/nav-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	position: relative;
}

.nav-sticky {
	position: sticky;
	top: 0;
	height: 100vh;
	/* background-image: url('./assets/nav-bg.png'); */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	display: none;
}

@media (min-width: 1200px) {
	.nav-sticky {
		display: block;
	}
}

.hamburger {
	position: absolute;
	top: 1rem;
	left: 1rem;
	z-index: 2;
}

@media (min-width: 1200px) {
	.hamburger {
		display: none;
	}
}
