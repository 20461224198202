.table-flex-column {
	display: flex;
	flex-direction: column;
}

.table-flex-row {
	display: flex;
	align-items: center;
}

.table-status-text {
	font-size: 10px;
	font-weight: 500;
	margin-left: 5px;
}

.table-cell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 5rem;
}

.table-customer-name {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: -5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table-price {
	font-size: 12px;
	font-weight: 500;
}

.table-name {
	font-size: 9px;
	font-weight: 400;
}

.table-version {
	font-size: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table-tag {
	font-size: 9px;
	font-weight: 400;
	padding: 1px 4px;
}

.p-column-title {
	font-size: 12px;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.p-column-header-content span svg {
	height: 10px;
}

.p-datatable-wrapper {
	border-radius: 4px;
}
