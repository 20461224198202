.login {
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.login-button {
    text-align: center !important;
    width: 70% !important; 
    margin: 1em auto !important;
    border-radius: 24px !important;
    justify-content: center !important; 
    display: flex !important;
    background-color: #FEF8E3 !important;
}
.login-card{
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: #005C64 !important;
    border-radius: 24px !important;
}

.login-image{
    margin-top: 10px;
}

.login-header{
    color: #FEF8E3 !important;
}