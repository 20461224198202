.action-bar {
	margin: 16px 0;
	flex-direction: column;
	align-items: flex-start;
}

@media (min-width: 768px) {
	.action-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

.action-bar-search-group {
	display: flex;
	align-items: center;
	border-radius: 4px;
	/* box-shadow: 0 0 10px rgba(61, 61, 61, 0.089); */
	border: 1px solid var(--color-light-grey);
	width: 100%;
	margin-right: 16px;
}

.action-bar-search-group-active-filters {
	display: flex;
	align-items: center;
	padding: 2px;
	padding-right: 8px;
	margin-left: 8px;
	border-right: 1px solid var(--color-light-grey);
}

.tag {
	margin-right: 4px;
	text-transform: capitalize;
	font-weight: 400;
	font-size: 10px;
	border-radius: 4px;
	padding: 1px 6px;
	white-space: nowrap;
}

.tag-label {
	display: none;
}

@media (min-width: 768px) {
	.tag-label {
		display: inline;
	}
}

.action-bar-search {
	display: flex;
	align-items: center;
	padding: 2px 8px;
	width: 100%;
}

.action-bar-search i {
	color: var(--color-dark-grey);
}

.action-bar-search input {
	border: none;
	padding: 8px;
	font-size: 14px;
	color: var(--color-dark-grey);
	width: 100%;
}

.action-bar-search input:focus {
	border: none;
	outline: none;
}

.action-bar-totals {
	margin-top: 16px;
}

@media (min-width: 768px) {
	.action-bar-totals {
		margin-top: 0;
		white-space: nowrap;
	}
}

.action-bar-totals p {
	font-size: 12px;
	color: var(--color-dark-grey);
	font-weight: 500;
}
