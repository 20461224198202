.current-page-report-template {
    /* color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' */
    font-size: 12px;
    color: var(--color-dark-grey);
    font-weight: 500;
}

.all-recipe-datatable {
    border-top: 1px solid #c3cedbb0;
}

p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    height: 2rem !important;
    min-width: 2rem !important;
}

.clear-filter-button {
    height: 2rem !important;
    width: 2rem !important;
    padding: 0.5rem !important;
}

.clear-filter-button span {
    color: var(--color-dark-grey);
}