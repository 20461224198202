ul.p-picklist-list {
    max-height: calc(100vh - 20rem);
    min-height: 20rem !important;
}

.p-picklist-target li.p-picklist-item {
    /* overflow: initial !important; */
    /* padding: 1.25rem 1.25rem !important; */
}
.p-picklist-target li.p-picklist-item::marker {
    font-weight: bold;
}

.machine-save-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
}

@media (min-width: 1200px) {
    .machine-save-button {
        margin-right: 5.5rem;
    }
}

.ingredients {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.ingredient-chip {
    margin: 0.5rem 0.25rem;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.ingredient-chip:hover {
    background-color: #d6d6d6;
    box-shadow: 0 0 0 1px #d6d6d6;
}

.ingredient-chip--highlight--error {
    background-color: #f860608c;
    border: 1px solid #f86060;
} 

.ingredient-chip--highlight {
    background-color: #609af88c;
    border: 1px solid #609af8;
}

.ingredient-chip--clear-filter {
    background-color: var(--color-dark-green);
    border: 1px solid var(--color-dark-green);
    color: #fff;
}

.ingredient-chip--clear-filter:hover {
    background-color: var(--color-light-green);
    border: 1px solid var(--color-light-green);
}

.recipe-item {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
}

.recipe-item--error {
    border: 1px solid #f86060;
    color: #f86060;
}

.recipe-item-image {
    width: 100%;
    max-width: 9rem;
    height: 9rem;
    overflow: hidden;
    position: relative;
    background-color: #e0e0e0;

}

.missing-ingredients {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 1rem);
}

.recipe-item-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.recipe-content {
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem 0.5rem;
    position: relative;
}

.recipe-content-title {
    font-size: 1rem;
    font-weight: 500;
}

.recipe-content-category {
    font-size: 0.8rem;
    color: #999;
}

.recipe-content-category::after {
    content: " | ";
    margin: 0 0.15rem;
}

.recipe-content-category:last-child::after {
    content: "";
    margin: 0;
}

.recipe-content-label {
    position: absolute;
    z-index: 1;
    right: 5.5rem;
    top: 2rem;
    width: auto;
    height: 2rem;
    border-radius: 0.25rem;
}

.recipe-content-label img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recipe-item-action {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1;
}
