@import '../../variables.css';

.nav {
    min-width: 18rem;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

@media (min-width: 1200px) {
    .nav {
        min-width: 20rem;
    }
}

@media (min-width: 1400px) {
    .nav {
        min-width: 25rem;
    }
}

.nav-header {
    margin: 0 1rem 1rem 1rem;
    border-bottom: 1px solid var(--color-dark-green)
}

.nav-header--logo {
    margin: 2rem 0;
    margin-left: 1rem;
    width: 10rem;
    height: auto;
}

.nav-list {
    list-style: none;
    margin: 0;
    padding: 0 1rem;
    margin-top: 2rem;
}

.nav-list-item {
    position: relative;
    margin-bottom: 0.5rem;
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
}

.nav-list-item:hover {
    border-radius: 0.25rem;
    background-color: #00a19931;
}

.nav-list-item a {
    padding: .75rem 0;
    height: 100%;
    width: 100%;
    display: block;
    padding-left: 3rem;
}

.nav-list-item--icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: white;
}

.nav-list-item--active {
    background-color: #00a19931;
    border-radius: 0.25rem;
    border: 2px solid var(--color-light-green);
}

.nav-list-item--active::before {
    content: '';
    height: 70%;
    background-color: var(--color-light-green);
    width: 0.2rem;
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 0.25rem 0.25rem 0;
}

.nav-list-item a {
    color: white;
    text-decoration: none;
}

.nav-user {
    margin: 1rem 1rem 0 1rem;
    border-top: 1px solid var(--color-dark-green);
    padding-top: 1rem;
}

.nav-user--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-user--inner-container {
    display: flex;
    align-items: center;
}

.nav-user--name {
    color: white;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.nav-user--role {
    text-transform: capitalize;
    color: white;
    font-size: 0.8rem;
}

.nav-user--avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
}

.menu-mobile {
    margin-top: 1rem;
}

div.hamburger {
    top: 1.5rem !important;
    right: 1.5rem !important;
    left: auto !important;
}


@media (min-width: 1200px) {
    .menu-mobile {
        display: none;
    }
}