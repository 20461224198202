@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* make container class like tailwind */

.layout-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin: 0.5rem;
  background-color: white;
  border-radius: 1rem;
  padding: 2rem 1rem;
  padding-top: 1rem;
  max-width: 100%;
  max-height: calc(100vh - 1rem);
  min-height: calc(100vh - 1rem);
  overflow-y: scroll;
}

@media (min-width: 1200px) {
  .layout-container {
    padding: 2rem;
    padding-top: 2rem;
    margin-left: 0;
    max-width: calc(100% - 18.5rem);
  }
}

@media (min-width: 1400px) {
  .layout-container {
    padding: 2rem;
    padding-top: 2rem;
    margin-left: 0;
    max-width: calc(100% - 25.5rem);
  }
}

.layout-container--login {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin: 1rem;
  background-color: white;
  border-radius: 1rem;
  padding: 2rem 1rem;
  padding-top: 1rem;
  max-width: 100%;
  max-height: calc(100vh - 2rem);
  min-height: calc(100vh - 2rem);
  overflow-y: auto;
}