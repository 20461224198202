.uploadedImage {
	height: 100%;
	width: 100%;
}

.placeholderImage {
	min-height: 150px;
}

.uploadedImage-image {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

@media screen and (min-width: 768px) {
	.uploadedImage {
		height: 250px;
		width: 250px;
	}
}

.disabled-input {
	background-color: #f0f0f0;
	border: 1px solid #dcdcdc;
	color: #2e2e2e;
}
