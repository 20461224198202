.container-details {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.general-info {
	width: 100%;
	margin-bottom: 2rem;
	background-color: #f5f5f5;
	padding: 2rem 1rem;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
}

h2 {
	border-bottom: 1px solid #ccc;
	padding-bottom: 1rem;
}

.container-form {
	width: 100%;
	background-color: #f5f5f5;
	padding: 2rem 1rem;
	border-radius: 5px;
	border: 1px solid #e0e0e0;
}

.ui.toggle.checkbox {
	min-height: 2.5rem;
	display: flex;
	align-items: center;
}

.info-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	font-size: 1rem;
	background-color: #e0e0e0;
	padding: 0.75rem 0.5rem;
	border-radius: 5px;
}

.info-flex span:first-child,
.info-flex-location-title {
	font-weight: bold;
}

.info-flex-location {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	font-size: 1rem;
	background-color: #e0e0e0;
	padding: 0.75rem 0.5rem;
	border-radius: 5px;
}

.info-flex-content {
	display: flex;
	align-items: center;
}

.info-flex-content span {
	margin-right: 0.5rem;
}

.info-flex-location-edit {
	cursor: pointer;
	margin-right: 0.5rem;
	padding: 0.25rem;
	border-radius: 50%;
	transition: all 0.3s ease-in-out;
}

.info-flex-location-edit:hover {
	background-color: #ccc;
	color: #fff;
}

@media (min-width: 1400px) {
	.container-details {
		display: grid;
		grid-template-columns: 1fr 3fr;
		grid-template-rows: 1fr;
		grid-gap: 2rem;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.general-info {
		flex-basis: 40%;
		min-width: 370px;
		height: 100%;
		margin-right: 2rem;
		margin-bottom: 0;
	}

	.container-form {
		padding-left: 1rem;
		position: relative;
	}
}
